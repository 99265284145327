import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigation, Pagination, A11y, Keyboard } from 'swiper/modules';
import { get, compact, merge } from '../lib/nodash';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import styled from 'styled-components';

import './content-slider.css';

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  transition-duration: 0;

  .swiper-container {
    height: 100%;
  }
  .swiper-pagination-bullet {
    background: ${(p) => p.navColor || '#fff'};
    opacity: 0.4;
  }
  .swiper-button-next,
  .swiper-button-prev {
    max-width: 16px;
    background-repeat: no-repeat;
    outline: none;

    &:after {
      content: none;
    }
  }

  .swiper-slide {
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    height: auto;
    background: ${(p) =>
      p.slideBackground ? p.slideBackground : 'transparent'};
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    opacity: 1;
  }
  .swiper-button-prev {
    background-size: contain;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23${(
      p
    ) => p.navColor}'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-button-next {
    background-size: contain;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23${(
      p
    ) => p.navColor}'%2F%3E%3C%2Fsvg%3E");
  }

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .swiper-button-next,
    .swiper-button-prev {
      max-width: 10px;
    }
  }

  .swiper-lazy-loading {
    display: none;
  }

  ${(p) => p.customCss}
`;

const ContentSlider = ({
  children,
  params,
  activeSlide,
  navColor = 'FFFFFF',
  slideBackground = 'transparent',
  extraSlides = [],
  customCss,
  swiperStyle,
  slideToSpeed = 0,
}) => {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper?.destroyed) {
      return setSwiper(null);
    }
    if (
      swiper !== null &&
      activeSlide !== false &&
      activeSlide !== undefined &&
      activeSlide > -1
    ) {
      swiper.slideTo(activeSlide, slideToSpeed);
    }
  }, [activeSlide, swiper]);

  const defaultParams = {
    loop: false,
    centeredSlides: false,
    slidesPerView: 1,
    spaceBetween: 20,
    modules: [Navigation, Pagination, A11y, Keyboard],
    watchSlidesVisibility: true,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
  };

  const mergedParams = merge(defaultParams, params);

  return (
    <StyledContainer
      navColor={navColor.replace('#', '')}
      slideBackground={slideBackground}
      customCss={customCss}
      className="content-slider-container"
    >
      <Swiper
        {...mergedParams}
        onSlideChange={(s) => {
          if (mergedParams.onSlideChange) {
            mergedParams.onSlideChange(s);
          }
        }}
        style={swiperStyle}
        initialSlide={activeSlide ? activeSlide : 0}
        onSwiper={(s) => {
          setSwiper(s);
          if (mergedParams.onSwiper) {
            mergedParams.onSwiper(s);
          }
        }}
      >
        {compact(children).map((x, i) => {
          return <SwiperSlide key={get('key', x) || i}>{x}</SwiperSlide>;
        })}
        {extraSlides.map((x, i) => {
          const { component: Component } = x;
          return (
            <SwiperSlide key={i}>
              <Component />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledContainer>
  );
};

ContentSlider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  params: PropTypes.object,
  activeSlide: PropTypes.number,
  centeredImages: PropTypes.bool,
  navColor: PropTypes.string,
  slideBackground: PropTypes.string,
  extraSlides: PropTypes.array,
  hasCustomNav: PropTypes.bool,
  customCss: PropTypes.string,
  slideToSpeed: PropTypes.number,
  swiperStyle: PropTypes.object,
};

export default memo(ContentSlider);
